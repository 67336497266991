<template>
  <div class="bomManagement dataM">
    <div class="tabData">
      <div class="tool">
        <el-tooltip v-premiSub="'BOM管理_导出'" effect="dark" content="导出" placement="top">
          <img src="@/assets/images/dataManage/export.png" @click="handleExport" />
        </el-tooltip>
        <upload v-premiSub="'BOM管理_导入'" ref="upload" templateName="产品BOM导入模板" templateParam="productBomInfo" @handleSearch="handleSearch" />
        <el-input v-premiSub="'BOM管理_搜索'" v-model="searchValues[activeTab]" style="width:220px" size="small" @keydown.enter.native="handleSearch(1)">
          <i slot="suffix" class="el-input__icon el-icon-search" @click="handleSearch(1)"></i>
        </el-input>
      </div>
      <el-tabs v-model="activeTab">
        <!-- 产品BOM -->
        <el-tab-pane label="产品BOM" name="0" class="BOMContent">
          <productBom ref="productBom" :searchValue="searchValues[activeTab]"/>
        </el-tab-pane>
        <!-- 装配BOM -->
        <!-- <el-tab-pane label="装配BOM" name="1" class="BOMContent">
          <assemblyBom ref="assemblyBom" :searchValue="searchValues[activeTab]"/>
        </el-tab-pane> -->
      </el-tabs>
    </div>
  </div>
</template>

<script>
import { exportProductBom } from '@/api/data.js'
import productBom from './components/productBom.vue'
// import assemblyBom from './components/assemblyBom.vue'
import upload from '@/components/upload/upload'
import dayjs from 'dayjs'
export default {
  name: "produceResource",
  components: {
    productBom,
    // assemblyBom,
    upload
  },
  data() {
    return {
      activeTab: '0',
      tab: ['产品BOM', '装配BOM',],
      searchValues:['',''],
      fileName: '',
    }
  },
  computed: {

  },
  watch: {
    
  },
  methods: {
    handleSearch(key) {
      if (this.activeTab == 0) {
        // 产品BOM
        this.$refs.productBom.handleSearch(key)
      } else {
        // 装配BOM
        this.$refs.assemblyBom.handleSearch(key)
      }
    },
    // 导出
    handleExport() {
      exportProductBom().then(res => {
        let blob = new Blob([res], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=utf-8'
        })
        let a = document.createElement('a')
        let url = window.URL.createObjectURL(blob)
        a.href = url
        a.download = `${this.tab[this.activeTab]}`+dayjs(new Date()).format('YYYY-MM-DD HH:mm:ss');
        // 将a标签添加到body中是为了更好的兼容性，谷歌浏览器可以不用添加
        document.body.appendChild(a)
        a.style.display = 'none'
        a.click()
        document.body.removeChild(a)
        window.URL.revokeObjectURL(url)
      })
    },
  }
}
</script>

<style lang="scss">
.bomManagement {
  text-align: left;
  color: #E8E8E8;
  margin-top: -5px;
  padding: 0px 2px;

  img {
    width: 22px;
    height: 22px;
    cursor: pointer;
  }

  .tabData {
    margin: 16px 30px 0px;
    padding: 20px 32px 0px 32px;
    background: #232323;
    border-radius: 8px;
    height: calc(100vh - 200px);
    position: relative;

    .tool {
      position: absolute;
      right: 30px;
      display: flex;
      align-items: center;
      z-index: 2;

      img {
        margin-right: 30px;
      }
    }
    .BOMContent{
      position: relative;
      height: calc(100vh - 280px);
    }
  }
}
</style>
<style scoped lang="scss"> 
::v-deep{
  .el-tabs__item.is-active{
    color: #FFFFFF!important;
    font-size: 20px!important;
  }
  .el-tabs__active-bar{
    display: none
  }
  .el-tabs__nav{
    line-height: 50px;
  }
}
</style>