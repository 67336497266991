<template>
  <div class="productBom">
    <!-- 树形 -->
    <el-tree 
      class="treeContent treeCom" 
      :data="treeSource" 
      :props="defaultProps" 
      :default-expand-all="true"
      :expand-on-click-node="false" 
      highlight-current ref="treeList" 
      node-key="id" 
      @node-click="selectNode">
      <div class="custom-tree-node" :class="`level${node.level}`" slot-scope="{ node, data }">
        <img v-if="node.level == 1" src="@/assets/images/dataManage/file-expand.png" />
        <img v-else src="@/assets/images/dataManage/file.png" />
        <span class="tree-name">{{ node.label }}</span>
      </div>
    </el-tree>
    <!-- table数据 -->
    <el-table class="tableContent" :data="tableSource" header-cell-class-name="tableHeader" style="width: 100%">
      <el-table-column v-for="item in showColumn" :key="item.label" v-bind="item"></el-table-column>
    </el-table>
    <pagination :total="total" :page.sync="page" :size.sync="size"  @getTableData="getBomList"/>
  </div>
</template>

<script>
import { getBomTree,getBomByLevel } from '@/api/data.js'
import pagination from '@/components/pagination'
export default {
  name: 'productBom',
  props:{
    searchValue:String
  },
  data() {
    return {
      defaultProps: {
        children: 'childrenBom',
        label: 'materialName'
      },
      treeSource: [],
      tableSource: [],
      columns: [
        {
          label: 'BOM单号',
          prop: 'bomCode',
          'min-width': 150,
        },
        {
          label: '物料编码',
          prop: 'topMaterialNo',
          'min-width': 150,
        }, {
          label: '产品名称',
          prop: 'materialName',
          'min-width': 150,
        }, {
          label: '图号',
          prop: 'partCode',
          'min-width': 150,
        }, {
          label: '版本号',
          prop: 'versionCode',
          'min-width': 150,
        },
      ],
      columns2:[
         {
          label: '物料名称',
          prop: 'materialName',
          'min-width': 100,
        },{
          label: '子项编码',
          prop: 'materialNo',
          'min-width': 100,
        },{
          label: '子项图号',
          prop: 'partCode',
          'min-width': 100,
        },{
          label: '子项版本号',
          prop: 'versionCode',
          'min-width': 100,
        },{
          label: '材质',
          prop: 'rawMaterial',
          'min-width': 100,
        },{
          label: '长（mm）',
          prop: 'length',
          'min-width': 100,
        },{
          label: '宽（mm）',
          prop: 'width',
          'min-width': 100,
        },{
          label: '高（mm）',
          prop: 'height',
          'min-width': 100,
        },{
          label: '属性',
          prop: 'property',
          'min-width': 100,
        },{
          label: '主单位',
          prop: 'quantityUnit',
          'min-width': 100,
        },{
          label: '需求量',
          prop: 'quantity',
          'min-width': 100,
        },
      ],
      currentData:null,
      parentData:null,
      total:0,
      page:1,
      size:10
    }
  },
  components: {
    pagination
  },
  computed:{
    showColumn(){
      return (!this.currentData || this.currentData?.level === 0) ? this.columns : this.columns2
    }
  },
  watch: {
    currentData:{
      handler(newVal){
        if(newVal){
          this.$nextTick(()=>{
            this.$refs['treeList'].setCurrentKey(newVal.id)
          })
          this.getBomList()
        }
      },
      immediate:true
    },
    size(){
      this.page = 1
      this.getBomList()
    },
  },
  mounted() {
    this.getBomTreeData()
  },
  methods: {
    handleSearch(key){
      if(!key){
        //重置
        this.page = 1
        this.getBomTreeData()
      }else{
        //搜索
        this.getBomList()
      }
    },
    // 获取BOM树
    getBomTreeData() {
      getBomTree({ page: 1, size: 999 }).then(res => {
        if (res.code == 0) {
          this.treeSource = res.data
          this.currentData = res.data.length ? res.data[0] : null
        } else {
          this.$message({
            message: res.msg,
            showClose: true,
            duration: 0,
            type: 'error',
          })
        }
      })
    },
    // 根据level获取BOM列表
    getBomList(val) {
      val && (this.page = val)
      getBomByLevel({
        id:this.currentData.topBomId,
        level:this.currentData.level,
        currentId:this.currentData.id,
        page:this.page,
        size:this.size,
        searchValue:this.searchValue
      }).then(res => {
        if (res.code == 0) {
          this.tableSource = res.data.records
          this.total = res.data.total
        } else {
          this.$message({
            message: res.msg,
            showClose: true,
            duration: 0,
            type: 'error',
          })
        }
      })
    },
    selectNode(data,node) {
      this.parentData = node.level === 1 ? null : node.parent.data
      this.currentData = data
    }
  }
}
</script>

<style scoped lang="scss">
.productBom {
  display: flex;
  width: 100%;

  .treeContent {
    width: 282px;
    padding-right: 18px;
    border-right: 1px solid #3E3E3E;
    height: calc(100vh - 300px);
    overflow-y: auto;
  }

  .tableContent {
    flex: 1;
    height: 100%;
    margin-left: 30px;
    max-height: calc(100vh - 330px);
    overflow-y: auto;
  }
  .mypage{
    bottom: 0px;
  }
}
::v-deep{
  .el-tree{
    .el-icon-caret-right:before{
      display:none;
    }
    .el-tree-node__content{
      height: 36px;
      font-size: 16px;
      color: #B9B9B9;
      .level1{
        font-weight: bold;
        color: #E8E8E8;
      }
      .level2{
        font-weight: 400;
        color: #E8E8E8;
      }
      img{
        width: 17px;
        height: 14px;
        margin-right: 10px;
      }
    }
    .el-tree-node.is-current > .el-tree-node__content{
      background: #474747!important;
      border-radius: 4px!important;
    }
  }
}
</style>
